import React, { useState, useRef, Suspense } from "react"
import Navbar from "./elements/navbar"
import Footer from "./elements/footer"
import NotificationBanner from "./elements/notification-banner"
import GradientBackground from "./gradient/GradientBackground"
import { Vector2, Vector3, Color } from 'three';
// import {useRouter} from 'next/router';


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";



import { Canvas } from '@react-three/fiber'
import ShaderPlane from "./gradient/ShaderPlane"


const Layout = ({ children, global, pageContext }) => {
  // const router = useRouter()
  const { navbar, footer, notificationBanner } = global.data

  // console.log(pageContext);

  const [bannerIsShown, setBannerIsShown] = useState(true)


  const pos = useRef(new Vector2(0.5,0.5));
  const [pos2, setPos2] = useState(new Vector2(1.25,0.8));
  const [pos3, setPos3] = useState(new Vector2(-0.25,0.8));
  const [pos4, setPos4] = useState(new Vector2(0.5,-0.25));
  const [pos5, setPos5] = useState(new Vector2(0.5,1.25));
  const posRef = useRef(new Vector2(0.75,0.25));
  const color1 = new Color(pageContext.color1).convertLinearToSRGB().getHex();
  const color2 = new Color(pageContext.color2).convertLinearToSRGB().getHex();

  // React.useEffect(()=>{
  //   console.log("Layout first render", pageContext.slug)
    // console.log(pageContext.color1, pageContext.color2)
    // console.log(new Color(pageContext.color1).convertSRGBToLinear().getHex(), new Color(pageContext.color2).convertSRGBToLinear().getHexString())
    // console.log(color1, color2)
  // },[])

  // "#FD745C", "#b4ccd6", "#c8dde6", "#b0c7d1", "#9ab2bc"
  // return (
  //   <div className="min-h-screen w-full background-color" id="#main">
  //     <div style={{width:"100%", height:"100vh", position:"fixed", top:"0", left:"0", zIndex:"1", overflow:"hidden"}}>
  //       {!["projects", "events", "articles"].includes(pageContext.slug) ? (
  //         <GradientBackground colorStart={color1} colorEnd={color2}  positionBlob={pos.current} scale={1.5} opacityFactor={1.0} circleScale={15}/>
  //       ) : ""}
  //       <div className="noise" style={{backgroundImage: `url("./noise-web.webp")`, pointerEvents:"none"}}></div>
  //     </div>

  //     <Navbar navbar={navbar} pageContext={pageContext} isBlack={false} />

  //     <div className="" style={{position:"relative", zIndex:"1", opacity:"1"}}>
  //       {children}
  //     </div>

  //     {/* Aligned to the bottom */}
  //     <Footer footer={footer} colorFooter={pageContext.colorFooter} />
  //   </div>
  // )

  return (
    <>
      {/* <div style={{width:"100%", height:"100vh", position:"fixed", top:"0", left:"0", zIndex:"1", overflow:"hidden"}}>
         {!["projects", "events", "articles"].includes(pageContext.slug) ? (
           <GradientBackground colorStart={color1} colorEnd={color2}  positionBlob={pos.current} scale={1.5} opacityFactor={1.0} circleScale={15}/>
         ) : ""}
         <div className="noise" style={{backgroundImage: `url("./noise-web.webp")`, pointerEvents:"none"}}></div>
       </div> */}
      {/* <div className="" style={{position:"relative", zIndex:"1", opacity:"1"}}> */}
        {children}
      {/* </div> */}
      {/* Aligned to the bottom */}
      <Footer footer={footer} colorFooter={pageContext.colorFooter} pageContext={pageContext}/>
    </>
  )
}

export default Layout
