import ErrorPage from "next/error"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { getPageData, getFieldPageData, fetchAPI, getGlobalData } from "/utils/api"
import { getLocalizedPaths } from "/utils/localize"
import { getArticlesPaginated, getEventsPaginated, getHomepageData, getProjectsPaginated } from "../utils/api"
import { getGradientColors } from "../utils/colors"
// import HeroBanner from "../components/sections/hero-banner"
// import Services from "../components/sections/services"
import ProjectsHome from "../components/sections/projects-home"
import EventsHome from "../components/sections/events-home"
import NewsHome from "../components/sections/news-home"
import ColumnText from "../components/sections/column-text"
import FieldsHome from "../components/sections/fields-home"


import Sections from "/components/sections"
import Seo from "/components/elements/seo"
import Layout from "/components/layout"

// import Hero from "../components/sections/hero"
const Hero = dynamic(() => import('../components/sections/hero'), {
  loading: () => <p>A carregar...</p>,
})

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const HomePage = ({ sections, metadata, preview, global, pageContext }) => {
  const router = useRouter()
  // console.log("RENDER PAGE", pageContext.heroBanner)
  // Check if the required data was provided
  // if (!router.isFallback && !sections?.length) {
  //   return <ErrorPage statusCode={404} />
  // }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>
  }

  return (
    <Layout global={global} pageContext={pageContext}>
      {/* Add meta tags for SEO*/}
      <Seo metadata={metadata} />
      {/* Display content sections */}
      {pageContext.heroBanner ? (<Hero data={pageContext.heroBanner} pageContext={pageContext}/>) : ""}
      {pageContext.firstDescription ? (<ColumnText data={pageContext.firstDescription} pageContext={pageContext}/>) : ""}
      {pageContext.fieldsSection ? (<FieldsHome data={pageContext.fieldsSection} fields={global.data.fields} pageContext={pageContext}/>) : ""}
      {pageContext.projectSection ? (<ProjectsHome data={pageContext.projectSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.eventSection ? (<EventsHome data={pageContext.eventSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.newsSection ? (<NewsHome data={pageContext.newsSection} fields={global.fields} pageContext={pageContext}/>) : ""}

      {/* {pageContext.services ? (<Services data={pageContext.services} pageContext={pageContext}/>) : ""}
      {pageContext.projectSection ? (<ProjectsHome data={pageContext.projectSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.eventSection ? (<EventsHome data={pageContext.eventSection} fields={global.fields} pageContext={pageContext}/>) : ""}
      {pageContext.newsSection ? (<NewsHome data={pageContext.newsSection} fields={global.fields} pageContext={pageContext}/>) : ""} */}
      {/* <Sections sections={sections} preview={preview} fields={global.data.fields} pageContext={pageContext} /> */}
    </Layout>
  )
}

export async function getServerSideProps(context) {
  // console.log("render slug")
  // console.log("context", context);
  const { params, locale, locales, defaultLocale, preview = null } = context
  // console.log("params", params);

  const globalLocale = await getGlobalData(locale)

  const fields = globalLocale.data.fields;
  // console.log("\n\n\n\n\n FIELDS", fields, "\n\n\n");

  let pageData = null;

  pageData = await getHomepageData(
    {},
    locale,
    preview
  )

  if (pageData == null) {
    // console.log("pageNULL")
    // Giving the page no props will trigger a 404 page
    return { props: {} }
  }

  // We have the required page data, pass it to the page component
  const { localizations, seo, gradientColor} = pageData
  // console.log("############# PAGE DATA ###############", pageData)

  const projects = await getProjectsPaginated("rank:asc", locale, false, "all", 1, 4);
  const events = await getEventsPaginated("rank:asc", locale, false, "all", 1, 4);
  const articles = await getArticlesPaginated("rank:asc", locale, false, "all", 1, 4);

  const colors = getGradientColors()[gradientColor];
  // console.log(colors)
  const pageContext = {
    locale: pageData.locale,
    locales,
    defaultLocale,
    // slug,
    // localizations,
    color1: colors.color1,
    color2: colors.color2,
    colorFooter: colors.colorFooter,
    heroBanner: pageData.heroBanner ?? null,
    firstDescription: pageData.firstDescription ?? null,
    fieldsSection: pageData.fieldsSection ?? null,
    // services: pageData.services ?? null,
    projectSection: pageData.projectSection ?? null,
    eventSection: pageData.eventSection ?? null,
    newsSection: pageData.newsSection ?? null,
    projects: projects ? projects : null,
    articles: articles ? articles : null,
    events: events ? events : null,
  }

  const localizedPaths = getLocalizedPaths(pageContext, "/")

  return {
    props: {
      preview,
      sections: null,
      metadata: seo,
      global: globalLocale,
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
    }
  }
}

export default HomePage
